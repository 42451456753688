@use '@angular/material' as mat;

@include mat.core();
/* You can add global styles to this file, and also import other style files */

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;400;600;700&display=swap');
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css");

$background: #F6F6F8;
$font-color: #FFFF;


html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

mat-progress-spinner.progreso {
    position: relative;
    top: 50%;
    left: 50%;
    // transform: translate(-50%, -50%);
    z-index: 5;
}


.spinner {
    color: white;
}

.mat-stroked-button.largeButton {
    height: 48px;
    line-height: 48px;
    font-size: 14px;
    font-weight: bold;

    .mat-icon {
        font-size: 36px;
        width: 36px;
        height: 36px;
        line-height: 36px;
    }
}


.tituloCabecera {
    font-weight: bolder;
    background-color: #f3f3f3;
}